angular.module("app")
    .controller("travelPolicyController", ["$log", "$state", "$uibModal", "travelPolicyService", function ($log, $state, $uibModal, travelPolicyService) {
        var vm = this;
        vm.loading = false;
        vm.pageNotReady = true;
        vm.travelPolicies = undefined;
        vm.includeInactive = false;
        vm.includeDeleted = false;
        vm.search = {
            searchField: undefined,
            isExpired: false,
        };
        vm.currentDefaultPolicy = undefined;
        vm.showDefault = false;

        (function init() {
            listTravelPolicies();
        })();

        vm.clearSearch = function () {
            vm.search = {
                searchField: undefined,
                isExpired: false,
            };
            vm.includeInactive = false;
        };

        vm.updateIncludeInactive = function () {

            if (vm.includeInactive) {
                delete vm.search.isExpired;
            } else {
                vm.search.isExpired = false;
            }
        };

        vm.removeTravelPolicy = function (travelPolicyReference) {
            modalConfirm().then(function yes() {
                return travelPolicyService.removeTravelPolicy(travelPolicyReference)
                    .then(function (data) {
                        listTravelPolicies();
                    });
            });
        };

        function modalConfirm() {
            return $uibModal.open({
                templateUrl: '/static/templates/travel-policy-removal.html',
                resolve: {
                    message: function () {
                        return 'This policy may be associated with payment methods or staffs.  Are  you sure you want to continue?';
                    }
                },
                controller: 'travelPolicyRemovalModalController as ctrl'
            }).result;
        }

        function listTravelPolicies() {
            vm.loading = true;
            return travelPolicyService.list(true)
                .then(function (results) {
                    vm.travelPolicies = results;
                })
                ['finally'](function () {
                vm.loading = false;
                vm.pageNotReady = false;
            });
        }

    }]);